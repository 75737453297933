import React from 'react';
import {ReactComponent as Logo} from '../assets/main/logo.svg';
import {EmployersIcon} from "../assets/icons/EmployersIcon";
import {useNavigate} from "react-router";
import {LogoutIcon} from "../assets/icons/LogoutIcon";
import {PromoIcon} from "../assets/icons/PromoIcon";
import {NewsIcon} from "../assets/icons/NewsIcon";
import {NotificationsIcon} from "../assets/icons/NotificationsIcon";
import {StatisticsIcon} from "../assets/icons/StatisticsIcon";
import {ReportsIcon} from "../assets/icons/ReportsIcon";
import {ClubIcon} from "../assets/icons/ClubIcon";
import {UsersIcon} from "../assets/icons/UsersIcon";
import {ReviewsIcon} from "../assets/icons/ReviewsIcon";
import {DeployersIcon} from "../assets/icons/DeployersIcon";
import {RefIcon} from "../assets/icons/RefIcon";

const SideNav = () => {
    const history = useNavigate();
    let destinations = [
        {
            href: '/employers',
            destinationKey: "employers",
            label: "Предприниматели",
            icon: <EmployersIcon
                color={window.location.pathname.includes("employers") ? "rgba(187, 255, 0, 1)" : "white"}/>
        },
        {
            href: '/users',
            destinationKey: "users",
            label: "Пользователи",
            icon: <UsersIcon
                color={window.location.pathname.includes("users") ? "rgba(187, 255, 0, 1)" : "white"}/>
        },
        {
            href: '/referral',
            destinationKey: "referral",
            label: "Накопительная",
            icon: <RefIcon
                color={window.location.pathname.includes("referral") ? "rgba(187, 255, 0, 1)" : "white"}/>
        },
        {
            href: '/deployers',
            destinationKey: "deployers",
            label: "Внедренцы",
            icon: <DeployersIcon
                color={window.location.pathname.includes("deployers") ? "rgba(187, 255, 0, 1)" : "white"}/>
        },
        {
            href: '/club',
            destinationKey: "club",
            label: "Члены клуба",
            icon: <ClubIcon
                color={window.location.pathname.includes("club") ? "rgba(187, 255, 0, 1)" : "white"}/>
        },
        {
            href: '/reports',
            destinationKey: "reports",
            label: "Отчеты",
            icon: <ReportsIcon
                color={window.location.pathname.includes("reports") ? "rgba(187, 255, 0, 1)" : "white"}/>
        },
        {
            href: '/notifications',
            destinationKey: "notifications",
            label: "Уведомления",
            icon: <NotificationsIcon
                color={window.location.pathname.includes("notifications") ? "rgba(187, 255, 0, 1)" : "white"}/>
        },
        {
            href: '/news',
            destinationKey: "news",
            label: "Новости",
            icon: <NewsIcon
                color={window.location.pathname.includes("news") ? "rgba(187, 255, 0, 1)" : "white"}/>
        }
    ]
    const Logout = () => {
        localStorage.clear();
        window.location.reload();
    }

    if(localStorage.getItem("role") === "Бухгалтер"){
        destinations = [
            {
                href: '/reports',
                destinationKey: "reports",
                label: "Отчеты",
                icon: <ReportsIcon
                    color={window.location.pathname.includes("reports") ? "rgba(187, 255, 0, 1)" : "white"}/>
            }
        ]
    }else if(localStorage.getItem("role") === "Владелец"){
        destinations = [
            {
                href: '/employers',
                destinationKey: "employers",
                label: "Предприятие",
                icon: <EmployersIcon
                    color={window.location.pathname.includes("employers") ? "rgba(187, 255, 0, 1)" : "white"}/>
            },
            {
                href: '/reports',
                destinationKey: "reports",
                label: "Отчеты",
                icon: <ReportsIcon
                    color={window.location.pathname.includes("reports") ? "rgba(187, 255, 0, 1)" : "white"}/>
            },
            {
                href: '/notifications',
                destinationKey: "notifications",
                label: "Уведомления",
                icon: <NotificationsIcon
                    color={window.location.pathname.includes("notifications") ? "rgba(187, 255, 0, 1)" : "white"}/>
            },
            {
                href: '/news',
                destinationKey: "news",
                label: "Новости",
                icon: <NewsIcon
                    color={window.location.pathname.includes("news") ? "rgba(187, 255, 0, 1)" : "white"}/>
            },
            {
                href: '/reviews',
                destinationKey: "reviews",
                label: "Отзывы",
                icon: <ReviewsIcon
                    color={window.location.pathname.includes("reviews") ? "rgba(187, 255, 0, 1)" : "white"}/>
            },
            {
                href: '/referral',
                destinationKey: "referral",
                label: "Накопительная",
                icon: <RefIcon
                    color={window.location.pathname.includes("referral") ? "rgba(187, 255, 0, 1)" : "white"}/>
            }
        ]
    }else if(localStorage.getItem("role") === "Внедренец"){
        destinations = [
            {
                href: '/employers',
                destinationKey: "employers",
                label: "Предприятия",
                icon: <EmployersIcon
                    color={window.location.pathname.includes("employers") ? "rgba(187, 255, 0, 1)" : "white"}/>
            },
            {
                href: '/notifications',
                destinationKey: "notifications",
                label: "Уведомления",
                icon: <NotificationsIcon
                    color={window.location.pathname.includes("notifications") ? "rgba(187, 255, 0, 1)" : "white"}/>
            },
            {
                href: '/referral',
                destinationKey: "referral",
                label: "Накопительная",
                icon: <RefIcon
                    color={window.location.pathname.includes("referral") ? "rgba(187, 255, 0, 1)" : "white"}/>
            }
        ]
    }
    return (
        <div style={{
            width: "240px",
            height: "100vh",
            background: "#121212",
            borderTopRightRadius: 12,
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 16,
            paddingBottom: 16,
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
        }}>
            <div style={{marginRight: 16}}>
                <Logo/>
            </div>
            <div style={{marginTop: 40, display: "flex", flexDirection: "column", gap: 8, width:"100%"}}>
                {
                    destinations.map((destination, index) => {
                        return (
                            <div key={index} onClick={() => {
                                history(destination.href)
                            }} style={
                                window.location.pathname.includes(destination.destinationKey) ?
                                    {
                                        width:"100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        gap: 8,
                                        borderRadius: 8,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        background: "#222",
                                        color: "rgba(187, 255, 0, 1)"
                                    } :
                                    {
                                        width:"100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        gap: 8,
                                        borderRadius: 8,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        background: "transparent",
                                        color: "white"
                                    }
                            }>
                                {destination.icon}
                                <p style={{fontSize: 14, fontWeight: "bold"}}>{destination.label}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div style={{marginTop: 40, width: "100%", paddingLeft: 12, paddingRight: 12, cursor: "pointer"}}>
                <div style={{display: "flex", gap: 8}} onClick={Logout}>
                    <LogoutIcon/>
                    <p style={{color: "#FF3131", fontWeight: "bold", fontSize: 14}}>Выход</p>
                </div>
            </div>
        </div>
    )

}

export default SideNav;
