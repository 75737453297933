import {createContext, useEffect, useState} from 'react';
import axios from 'axios';

const AuthContext = createContext({});

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
if (localStorage && localStorage.sorpaToken) {
    const access_token = localStorage.getItem('sorpaToken');
    axios.defaults.headers.common['Authorization'] = `Token ${access_token}`;
}
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({});
    const [me, setMe] = useState({});
    const [filterCities, setFilterCities] = useState([]);
    const [filterCategories, setFilterCategories] = useState([]);
    const [users, setUsers] = useState([]);
    useEffect(() => {
        setAuth(localStorage.getItem('sorpaToken'));
    }, [localStorage.getItem('sorpaToken')]);
    return (
        <AuthContext.Provider value={{me, setMe, auth, setAuth, filterCities, setFilterCities, filterCategories, setFilterCategories}}>{children}</AuthContext.Provider>
    );
};

export default AuthContext;
