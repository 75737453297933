import {UsersTopNav} from "./UsersTopNav";
import {
    Button,
    Card,
    Divider,
    Input,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Tooltip, ModalContent, ModalHeader, ModalBody, Textarea, Modal, Spinner
} from "@nextui-org/react";
import {Select, SelectItem} from "@nextui-org/react";
import {SearchIcon} from "../../assets/icons/SearchIcon";
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/authContext";
import {useNavigate} from "react-router";
import {EditIcon} from "../../assets/icons/EditIcon";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";
import axios from "axios";
import {BlockUser} from "../../assets/icons/BlockUser";
import {WalletIcon} from "../../assets/icons/WalletIcon";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {AddIcon} from "../../assets/icons/AddIcon";
import {useAsyncList} from "@react-stately/data";
import {useInfiniteScroll} from "@nextui-org/use-infinite-scroll";
import {PauseIcon} from "../../assets/icons/PauseIcon";
import {PlayIcon} from "../../assets/icons/PlayIcon";
import {BonusAddIcon} from "../../assets/icons/BonusAddIcon";

const Users = () => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [addBonus, setAddBonus] = useState(false);
    const [addBalance, setAddBalance] = useState(false);
    const [changeRefModal, setChangeRefModal] = useState(false);
    const [changeID, setChangeID] = useState(null);
    const [user, setUser] = useState({});
    const [users, setUsers] = useState([]);
    const [count, setCount] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [hasMore, setHasMore] = React.useState(false);
    const [frozenSum, setFrozenSum] = React.useState(0);
    const [balanceSum, setBalanceSum] = React.useState(0);
    const [bonusSum, setBonusSum] = React.useState(0);
    const [bonusAddSum, setBonusAddSum] = React.useState(0);
    const [searchDetails, setSearchDetails] = useState({
        name: "",
        branch: "",
        date_from: "",
        date_to: ""
    })

    const getUser = (user_id) => {
        axios.get(`https://backend.sorpa.com/users/${user_id}/get/admin/data/`).then(res => {
            setUser(res.data);
        }).catch(err => {
            console.log(err)
            setError(err.response.data.message);
        })
    }

    let list = useAsyncList({
        async load({signal, cursor}) {
            console.log(url);

            if (cursor) {
                setIsLoading(false);
            }

            const res = await axios.get(cursor || url);
            let json = await res.data

            setIsLoading(false);
            setUsers(json.results);
            setCount(json.count);
            setFrozenSum(json.bonus_frozen_balance);
            setBonusSum(json.bonus_balance);
            setBonusAddSum(json.bonus_add_balance);
            setBalanceSum(json.main_balance);
            setHasMore(json?.next !== null);

            const decodedURL = decodeURIComponent(json?.next);

            if (Array.isArray(json.results)){
                return {
                    items: json?.results,
                    cursor: decodedURL,
                };
            }else{
                return {
                    items: [],
                    cursor: null,
                };
            }
        },
    });

    const [loaderRef, scrollerRef] = useInfiniteScroll({hasMore, onLoadMore: list.loadMore});

    let url = "https://backend.sorpa.com/users/list/";

    useEffect(() => {
        const searchParams = new URLSearchParams(searchDetails);
        url = `https://backend.sorpa.com/users/list/?${searchParams}`
        list.reload();
    }, [searchDetails]);
    return (
        <>
            <UsersTopNav/>
            <div style={{
                color: "white",
                width: "100%",
                height: "calc(100vh - 72px)",
                boxSizing: "border-box",
                paddingRight: 16,
                paddingLeft: 16,
                paddingBottom: 16
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    background: "black",
                    maxWidth: 1300,
                    height: "100%"
                }}>
                    <Card style={{width: "100%", background: "#121212", padding: 24}}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            gap: 16
                        }}>
                            <p style={{color: "white", fontWeight: "bold"}}>{count} Пользователей</p>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 24,
                                alignItems: "center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    gap: 12,
                                    alignItems: "center"
                                }}>
                                    <div style={{width: 300, display:"flex", alignItems:"center", gap:12}}>
                                        <Input
                                            startContent={<SearchIcon/>}
                                            type={"email"}
                                            size={"sm"}
                                            radius="lg"
                                            style={{color: "white"}}
                                            className={"input-text-color"}
                                            classNames={{
                                                label: "input-text-color",
                                                input: "text-white/90",
                                                innerWrapper: "input-text-color",
                                                inputWrapper: "input-main",
                                            }}
                                            placeholder={"Поиск по номеру"}
                                            onChange={(event) => {
                                                setSearchDetails({...searchDetails, search: event.target.value});
                                                if (event.target.value === '') {
                                                    setSearchDetails({...searchDetails, search: ""});
                                                }
                                            }}
                                        />
                                    </div>
                                    <div style={{width: 160, display:"flex", alignItems:"center", gap:12}}>
                                        <div>
                                            <p className={"text-white font-bold"}>Основная сумма</p>
                                            <p className={"text-white font-normal"}>{balanceSum.toLocaleString("RU-ru")}</p>
                                        </div>
                                    </div>
                                    <div style={{width: 160, display:"flex", alignItems:"center", gap:12}}>
                                        <div>
                                            <p className={"text-white font-bold"}>Добавка</p>
                                            <p className={"text-white font-normal"}>{bonusSum.toLocaleString("RU-ru")}</p>
                                        </div>
                                    </div>
                                    <div style={{width: 160, display:"flex", alignItems:"center", gap:12}}>
                                        <div>
                                            <p className={"text-white font-bold"}>Бонусы</p>
                                            <p className={"text-white font-normal"}>{bonusAddSum.toLocaleString("RU-ru")}</p>
                                        </div>
                                    </div>
                                    <div style={{width: 160, display:"flex", alignItems:"center", gap:12}}>
                                        <div>
                                            <p className={"text-white font-bold"}>Накопительный</p>
                                            <p className={"text-white font-normal"}>{frozenSum.toLocaleString("RU-ru")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                            {
                                !isLoading && count > 0 && <Table
                                    isStriped isHeaderSticky
                                    classNames={{
                                        base: 'table-wrapper',
                                        table: "table",
                                        wrapper: "base-wrapper max-w-[1500px]"
                                    }}
                                    className={"h-full"}
                                    aria-label="Example table with infinite pagination"
                                    baseRef={scrollerRef}
                                    bottomContent={
                                        hasMore ? (
                                            <div ref={loaderRef} className="flex w-full justify-center">
                                                {/*<Spinner  color="transparent" className={"bg-transparent text-transparent"} />*/}
                                            </div>
                                        ) : null
                                    }
                                >
                                    <TableHeader>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>ID</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>Имя</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>Номер телефона</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>Основной баланс</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>Бонусы <br/> (добавка)</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>Бонусы <br/> (сумма)</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>Накопительный <br/> (заморозка)</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>Активный аккаунт?</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold",
                                            textAlign: "center"
                                        }}>Действия</TableColumn>
                                    </TableHeader>
                                    <TableBody
                                        isLoading={isLoading}
                                        items={list.items}
                                        // loadingContent={<Spinner color="transparent" className={"bg-transparent -transparent"}/>}
                                    >
                                        {(user) => (
                                            <TableRow>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.id}</p></TableCell>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.name}</p></TableCell>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.phone_number}</p></TableCell>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.balance.balance_sum}</p></TableCell>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.balance.dobavka_sum}</p></TableCell>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.balance.add_sum}</p></TableCell>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.ref_sum}</p></TableCell>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.user_status}</p></TableCell>

                                                <TableCell style={{width: 120}}>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>
                                                        <Tooltip content="Пополнить бонусы">
                                                            <Button isIconOnly={true}
                                                                    onClick={() => {
                                                                        setAddBonus(true);
                                                                        setChangeID(user.id);
                                                                        getUser(user.id);
                                                                    }}
                                                                    style={{background: "none"}}>
                                                                <BonusAddIcon/>
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip content="Пополнить баланс">
                                                            <Button isIconOnly={true}
                                                                    onClick={() => {
                                                                        setAddBalance(true);
                                                                        setChangeID(user.id);
                                                                        getUser(user.id);
                                                                    }}
                                                                    style={{background: "none"}}>
                                                                <WalletIcon/>
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip content="Сменить REF код">
                                                            <Button isIconOnly={true}
                                                                    onClick={() => {
                                                                        setChangeRefModal(true);
                                                                        setChangeID(user.id);
                                                                        getUser(user.id);
                                                                    }}
                                                                    style={{background: "none"}}>
                                                                <EditIcon/>
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            }
                        </div>
                    </Card>
                </div>
                <Modal isOpen={changeRefModal} style={{background: "#121212"}} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 0,
                            paddingRight: 16
                        }}>
                            <h2 style={{color: "white"}}>Изменить Промокод: {user?.phone_number}</h2>
                            <Button onClick={() => {
                                setChangeID(null);
                                setChangeRefModal(false);
                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                        </ModalHeader>
                        <ModalBody>
                            {
                                error && <p style={{color: "red"}}>{error}</p>
                            }
                            <div style={{display:"flex" , alignItems:"center", flexDirection:"column", gap:16}}>
                                <Input
                                    type={"text"}
                                    value={user.ref_code}
                                    placeholder="Код"
                                    size={"sm"}
                                    radius="lg"
                                    maxLength={8}
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    onChange={(event) => {
                                        setUser({...user, ref_code: event.target.value});
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        axios.post(`https://backend.sorpa.com/users/${changeID}/change/ref/code/`,
                                            {"ref_code": user.ref_code}).then(
                                            res => {
                                                setChangeRefModal(false);
                                                setChangeID(null);
                                                setUser({});
                                            }
                                        ).catch(
                                            err => {
                                                setError(err.response.data.message);
                                                console.log(err)
                                            }
                                        )
                                    }}
                                    style={{
                                    background: "#BF0",
                                    color: "black",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    marginBottom:20,
                                    width:"100%"
                                }}
                                >
                                    <p>Изменить</p>
                                </Button>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Modal isOpen={addBalance} style={{background: "#121212"}} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 0,
                            paddingRight: 16
                        }}>
                            <h2 style={{color: "white"}}>Пополнить баланс {user.phone_number}</h2>
                            <Button onClick={() => {
                                setAddBalance(false);
                                setError("");
                                setSuccess("");
                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                        </ModalHeader>
                        <ModalBody>
                            {
                                success && <p style={{color: "green"}}>{success}</p>
                            }
                            {
                                error && <p style={{color: "red"}}>{error}</p>
                            }
                            <div style={{display:"flex" , alignItems:"center", flexDirection:"column", gap:16}}>
                                <Input
                                    disabled={isLoading}
                                    type={"number"}
                                    placeholder="Сумма"
                                    value={user.fill_sum}
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    onChange={(event) => {
                                        setUser({...user, fill_sum:event.target.value});
                                        setError("");
                                    }}
                                />
                                <Button
                                    disabled={isLoading}
                                    style={{
                                        background: "#BF0",
                                        color: "black",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        marginBottom:20,
                                        width:"100%"
                                }}
                                        onClick={() => {
                                            setIsLoading(true);
                                            axios.post(`https://backend.sorpa.com/users/${changeID}/fill/`, {"fill_sum": user.fill_sum}).then(
                                               res => {
                                                   setIsLoading(false);
                                                   setSuccess(res.data.success);
                                                   console.log(res.data.success);
                                                   setUser({...user, fill_sum: 0});
                                                   list.reload();
                                               }
                                            ).catch(
                                                err => {
                                                    setError(err.response.data.message);
                                                }
                                            )
                                        }}
                                >
                                    {
                                        isLoading ? <Spinner color={"secondary"}/> :
                                            <p>Пополнить</p>
                                    }
                                </Button>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Modal isOpen={addBonus} style={{background: "#121212"}} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 0,
                            paddingRight: 16
                        }}>
                            <h2 style={{color: "white"}}>Пополнить добавки {user.phone_number}</h2>
                            <Button onClick={() => {
                                setAddBonus(false);
                                setError("");
                                setSuccess("");
                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                        </ModalHeader>
                        <ModalBody>
                            {
                                success && <p style={{color: "green"}}>{success}</p>
                            }
                            {
                                error && <p style={{color: "red"}}>{error}</p>
                            }
                            <div style={{display:"flex" , alignItems:"center", flexDirection:"column", gap:16}}>
                                <Input
                                    disabled={isLoading}
                                    type={"number"}
                                    placeholder="Сумма"
                                    value={user.fill_sum}
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    onChange={(event) => {
                                        setUser({...user, fill_sum:event.target.value});
                                        setError("");
                                    }}
                                />
                                <Button
                                    disabled={isLoading}
                                    style={{
                                        background: "#BF0",
                                        color: "black",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        marginBottom:20,
                                        width:"100%"
                                    }}
                                    onClick={() => {
                                        setIsLoading(true);
                                        axios.post(`https://backend.sorpa.com/users/${changeID}/fill/bonus/`, {"fill_sum": user.fill_sum}).then(
                                            res => {
                                                setIsLoading(false);
                                                setSuccess(res.data.success);
                                                console.log(res.data.success);
                                                setUser({...user, fill_sum: 0});
                                                list.reload();
                                            }
                                        ).catch(
                                            err => {
                                                setError(err.response.data.message);
                                            }
                                        )
                                    }}
                                >
                                    {
                                        isLoading ? <Spinner color={"secondary"}/> :
                                            <p>Пополнить</p>
                                    }
                                </Button>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        </>
    )
}

export default Users;
